.btn-top {
    background: none; /* 배경 없음 */
    border: none; /* 테두리 없음 */
    cursor: pointer; /* 마우스 커서 변경 */
}

#kbodle {
    max-width: 450px;
    margin: 0 auto;
}

.search-bar input,
#result {
    background: white;
}

#result ul {
    margin: 5px 0 5px 0 !important;
}

@media (min-width: 450px) {
    .search-bar input {
        margin-top: 30px;
    }
    #result {
        width: 450px;
    }
    .legend-div {
        font-size: 14px;
    }
}

@media (max-width: 450px) {
    #kbodle,
    .search-bar input {
        max-width: 95vw;
    }
    .search-bar input {
        width: 95vw !important;
        margin-top: 20px;
    }
    #result {
        width: 95vw !important;
    }
    .legend-div {
        font-size: 12px;
    }
}

@media (max-width: 600px) {
    .search-container i {
        top: 65% !important;
    }
}

.search-bar {
    position: absolute;
}

.search-bar input {
    width: 450px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    text-align: left;
    border-radius: 15px;
    border: 1px solid #dcdcdc;
    height: 50px;
    font-size: 1rem;
    font-family: "NanumSquareNeo", "Gothic";
    padding-left: 45px;
}

.search-container {
    position: relative;
    display: inline-block;
}

.search-container i {
    position: absolute;
    top: 70%;
    left: 20px;
    transform: translateY(-50%);
    color: gray;
}

#result {
    position: absolute;
    overflow-y: auto;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2);
    border-radius: 15px 15px 15px 15px;
}

.result-text {
    padding: 0px;
    cursor: default;
}

.result-list {
    list-style-type: none;
    padding: 0;
}

.result-list:hover,
.result-list:focus {
    background-color: #ececec;
    cursor: pointer;
    outline: 0;
}

#top-btn-group button {
    color: black;
    padding-inline: 6px;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1048;
}

.legend-div {
    width: 100%;
    color: white;
    background-color: #6c757d;
    border: 1px solid #000000;
    margin: 0 1px 0 1px;
    white-space: nowrap;
    padding: 5px 0px 5px 0px;
    overflow: hidden;
}

.blank-div {
    margin-top: 5px;
    background-color: #ececec;
    height: 38px;
    line-height: 26px;
    border: 1px solid #cccccc;
    color: #ececec;
    font-weight: 700;
}

.font-black {
    color: #282c34 !important;
}

.hide-text div {
    color: transparent;
}

.out-div {
    margin-top: 5px;
    background-color: #282c34;
    border: 1px solid #cccccc;
    height: 38px;
    line-height: 26px;
}

.green-div {
    margin-top: 5px;
    background-color: #09e117;
    height: 38px;
    border: 1.5px solid #282c34;
    color: #282c34;
    font-weight: 700;
    line-height: 26px;
}

.orange-div {
    margin-top: 5px;
    background-color: orange;
    height: 38px;
    line-height: 26px;
    border: 1.5px dashed snow;
    color: #282c34;
    font-weight: bold;
}

.div-flip {
    transition: all 0.1s ease 0.3s;
    animation: flip 0.5s ease-in-out;
}

@keyframes flip {
    0% {
        transform: rotateX(-180deg);
    }
    100% {
        transform: rotateX(0);
    }
}

.legend-div:nth-child(1) {
    border-radius: 8px 0px 0px 8px;
    width: 5em;
}

.legend-div:nth-child(3),
.legend-div:nth-child(4),
.legend-div:nth-child(5) {
    width: 3.5em;
}

.legend-div:nth-child(2),
.legend-div:nth-child(6) {
    width: 5em;
}

.legend-div:nth-child(7) {
    border-radius: 0px 10px 10px 0px;
    width: 6em;
}

.player-img-container {
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 1px solid gainsboro;
    border-radius: 50%;
    overflow: clip;
    background-color: white;
}

.player-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    transform: scale(1.1);
    vertical-align: middle;
}

.modal-player {
    position: relative;
    border-radius: 1.2rem;
    padding: 1rem 1rem 1rem 1rem;
    display: flex;
}

.modal-player::before {
    content: "";
    background-size: cover;
    opacity: 0.1;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-position: right center;
    background-repeat: no-repeat;
    background-size: auto 80%;
    display: block;
}

.bg-kiw {
    background-color: #ad2d5e;
}
.bg-kiw::before {
    background-image: url("../../assets/images/s-logos/kiw-small-logo.svg");
}
.bg-ssg {
    background-color: #f94d4d;
}
.bg-ssg::before {
    background-image: url("../../assets/images/s-logos/ssg-small-logo.svg");
}
.bg-lg {
    background-color: #e03461;
}
.bg-lg::before {
    background-image: url("../../assets/images/s-logos/lg-small-logo.svg");
}
.bg-sam {
    background-color: #3572bc;
}
.bg-sam::before {
    background-image: url("../../assets/images/s-logos/sam-small-logo.svg");
}
.bg-lot {
    background-color: #343d71;
}
.bg-lot::before {
    background-image: url("../../assets/images/s-logos/lot-small-logo.svg");
}
.bg-han {
    background-color: #ff703a;
}
.bg-han::before {
    background-image: url("../../assets/images/s-logos/han-small-logo.svg");
}
.bg-kia {
    background-color: #ea0029;
}
.bg-kia::before {
    background-image: url("../../assets/images/s-logos/kia-small-logo.svg");
}
.bg-nc {
    background-color: #274c82;
}
.bg-nc::before {
    background-image: url("../../assets/images/s-logos/nc-small-logo.svg");
}
.bg-kt {
    background-color: #272727;
}
.bg-kt::before {
    background-image: url("../../assets/images/s-logos/kt-small-logo.svg");
}
.bg-doo {
    background-color: #2c2e44;
}
.bg-doo::before {
    background-image: url("../../assets/images/s-logos/doo-small-logo.svg");
}

.modal-player-img img {
    border-radius: 0.8rem;
    max-width: 4rem;
    background-color: white;
}

.modal-player-text {
    padding-left: 16px;
    color: white;
    white-space: pre-line;
    padding-top: 10px;
    z-index: 100;
}

.modal {
    --bs-modal-border-radius: 20px;
}

.modal-result-tiles {
    border: 1px solid #dcdcdc;
    border-radius: 1.2rem;
    background-color: white;
    padding: 0.5rem;
    font-size: 10px;
    min-width: 128px;
    white-space: nowrap;
}

.modal-result-right {
    margin-left: 10px;
    gap: 10px;
}

.modal-result {
    border: 1px solid #dcdcdc;
    border-radius: 1.2rem;
    background-color: snow;
    padding: 1rem 0.8em 1rem 0.8rem;
    display: flex;
    justify-content: space-evenly;
}

.btn-text-lg span {
    font-size: 1rem !important;
}
